// import React, { useEffect, useState } from 'react';
// import NewsCard from './NewsCard';

// const API_KEY = "8a6b525c9925801914d2702fe4683567";
// const url = 'https://gnews.io/api/v4/search?q=';

// const Main = () => {
//   const [articles, setArticles] = useState([]);

//   useEffect(() => {
//     fetchNews("India");
//   }, []);

//   const fetchNews = async (query) => {
//     try {
//       const res = await fetch(`${url}${query}&apikey=${API_KEY}`);
//       const data = await res.json();
//       setArticles(data.articles);
//     } catch (error) {
//       console.error("Error fetching news:", error);
//     }
//   };

//   return (
//     <main>
//       <div className="cards-container container flex">
//         {articles.map((article, index) => (
//           <NewsCard key={index} article={article} />
//         ))}
//       </div>
//     </main>
//   );
// };

// export default Main;



import React, { useEffect, useState } from 'react';
import NewsCard from './NewsCard';
import Navbar from './Navbar';

const API_KEY = "8a6b525c9925801914d2702fe4683567";
const url = 'https://gnews.io/api/v4/search?q=';

const Main = () => {
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    fetchNews("India"); // Default fetch on load
  }, []);

  const fetchNews = async (query) => {
    try {
      const res = await fetch(`${url}${query}&apikey=${API_KEY}`);
      const data = await res.json();
      setArticles(data.articles);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const handleCategoryClick = (category) => {
    fetchNews(category); // Fetch news based on the selected category
  };

  const handleSearch = () => {
    if (searchQuery) {
      fetchNews(searchQuery);
      setSearchQuery(''); // Clear the input after search
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <main>
      <Navbar
        onCategoryClick={handleCategoryClick}
        toggleTheme={toggleTheme}
        theme={theme}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <div className="cards-container container flex">
        {articles.map((article, index) => (
          <NewsCard key={index} article={article} />
        ))}
      </div>
    </main>
  );
};

export default Main;
