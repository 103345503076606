import React from 'react';

const NewsCard = ({ article }) => {
  const { image, title, source, publishedAt, description, url } = article;
  const date = new Date(publishedAt).toLocaleString('en-US', {
    timeZone: 'Asia/Jakarta'
  });

  return (
    <div className="card">
      <div className="card-header">
        <img src={image || "https://via.placeholder.com/400x200"} alt="news" loading="lazy" />
      </div>
      <div className="card-content flex">
        <h3>{title}</h3>
        <h6>{source.name} • {date}</h6>
        <p>{description}</p>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <button className="search-btn">Read More</button>
        </a>
      </div>
    </div>
  );
};

export default NewsCard;
