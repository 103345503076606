// import React from 'react';

// const Navbar = ({ toggleTheme, theme }) => {
//   return (
//     <nav>
//       <div className="main-nav flex container">
//         <div className="logo">
//           <a href="/" className="flex">
//             <span>NewsSync</span>
//             <p>Stay Synced, Stay Informed!</p>
//           </a>
//         </div>
//         <div id="nav-links-container" className="nav-links">
//           <ul className="flex">
//             <li className="nav-item hover-link" id="latest">Latest</li>
//             <li className="nav-item hover-link" id="cricket">Cricket</li>
//             <li className="nav-item hover-link" id="technology">Technology</li>
//           </ul>
//         </div>
//         <div className="search-bar flex">
//           <input id="search-text" type="text" className="news-input" placeholder="e.g. Cricket" />
//           <button id="search-btn" className="search-btn">Search<i className="fa-solid fa-magnifying-glass"></i></button>
//         </div>
//         <div className="theme-switch-wrapper">
//           <label className="theme-switch flex" htmlFor="checkbox">
//             <input type="checkbox" id="checkbox" checked={theme === "dark"} onChange={toggleTheme} />
//             <span>{theme === "dark" ? "Light" : "Dark"} Mode</span>
//           </label>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


import React from 'react';

const Navbar = ({ toggleTheme, theme, onCategoryClick, searchQuery, setSearchQuery, handleSearch }) => {
  return (
    <nav>
      <div className="main-nav flex container">
        <div className="logo">
          <a href="/" className="flex">
            <span>NewsSync</span>
            <p>Stay Synced, Stay Informed!</p>
          </a>
        </div>
        <div id="nav-links-container" className="nav-links">
          <ul className="flex">
            <li className="nav-item hover-link" onClick={() => onCategoryClick('India')}>Latest</li>
            <li className="nav-item hover-link" onClick={() => onCategoryClick('World')}>World</li>
            <li className="nav-item hover-link" onClick={() => onCategoryClick('Technology')}>Technology</li>
            <li className="nav-item hover-link" onClick={() => onCategoryClick('Business')}>Business</li>
            <li className="nav-item hover-link" onClick={() => onCategoryClick('Sports')}>Sports</li>
            <li className="nav-item hover-link" onClick={() => onCategoryClick('Cricket')}>Cricket</li>

          </ul>
        </div>
        <div className="search-bar flex">
          <input
            id="search-text"
            type="text"
            className="news-input"
            placeholder="e.g. Cricket"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            id="search-btn"
            className="search-btn"
            onClick={handleSearch}
          >
            Search<i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        <div className="theme-switch-wrapper">
          <label className="theme-switch flex" htmlFor="checkbox">
            <input
              type="checkbox"
              id="checkbox"
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
            <span>{theme === "dark" ? "Light" : "Dark"} Mode</span>
          </label>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
